export default {    
    fetchStatus: {
      READY: "READY",
      FETCHING: "FETCHING"
    },
    apiBaseUrl: {
        development: "https://workspace.gifihome.id",
        production: "https://workspace.gifihome.id",        
        // production: "http://127.0.0.1:8000",
        // development: "http://127.0.0.1:8000",
        // development: "http://10.10.10.17:8000",
        // production: "http://10.10.10.17:8000",
    },
    wsUrl : {
      // production : "wss://vendingmachine-be.myappscenter.com/ws/",
      production : "wss://workspace.gifihome.id/ws/",
      development : "ws://127.0.0.1:8000/ws/"            
    },
    localStorageKey : {
      'formPembelian' : '_xyZ1223lda_ead_',
      USER: "__gifiHomesUser",
      ACCESS_TOKEN: "__gifiHomesAccessToken",
      PERM_GROUP : "__permGroup",
      PERM_DETAIL : "__permDetail",
      USER_PERM : "__userperm" 
    },
    initLocation : {
      lat : -5.3664358,
      lng : 105.2464834,
    },
    expiresSession : 86400,
    itemPerPage : 20,
    timeOutValue : 5000,
    googleMapApiKey : 'AIzaSyDm751ucwcFMFw6dztSqEfgCKeYoz2hTYc',
    endPoint : {
      authv1 : `/auth/api/v1/`,
      bankv1 : `/akunting/api/v1/bank/`,
      billing : `/billing/api`,
      gantiStatusIsolirAktif : `/billing/api/v1/ganti-status-isolir-aktif/`,
      checkStatusUserPpoe : `/billing/api/v1/check-status-user-pppoe/`,
      isolirPelangganSingle : `/billing/api/v1/isolir-pelanggan-single/`,
      bukaIsolirPelangganSingle : `/billing/api/v1/buka-isolir-pelanggan-single/`,
      billingStatusLog : `/billing/api/v1/billing-status-log/`,
      billingFotov1 : `api/v1/billing-foto/`,
      billingv1 : `/api/v1/billing/`,
      billingChangeStatus : `/billing/api/change-status/`,
      billingGantiPerangkat : `/billing/api/v1/ganti-perangkat/`,
      billingMasterReferralv1 : `/billing/api/v1/master-referral/`,
      billingDownloadPelanggan : `/billing/api/v1/billing-download-pelanggan/`,
      backupDatabase : `/master/api/backup-file-list/`,
      backupDatabaseExec : `/master/api/exec-backup/`,
      changePassword : `/auth/api/change-password`,
      dashboard : `/api/v1/dashboard/`,
      devices : `/master/api/devices/`,
      devicesShowPelanggan : `/master/api/devices-show-pelanggan/`,
      checkMikrotikStatus : `/master/api/v1/check-mikrotik-status/`,
      devicesProfile : `/master/api/devices-profile/`,
      gantiTeknis : `/billing/api/ganti-teknis/`,
      hitungGaji : `/hrd/api/hitung-gaji/`,
      hrd : `/hrd/api/`,
      initialSetup : `/master/api/initial-setup/`,
      ippool : `/master/api/ippool/`,
      kontak : `/kontak/api/kontak`,
      jurnalGroup : '/akunting/api/v1/jurnal-group/',
      tagihan : `/tagihan/api/v1/tagihan/`,
      koreksiStatusBilling : `/api/v1/koreksi-status-billing/`,

      // kategori
      kategori : '/produk/api/kategori',
      kategoriv1 : '/produk/api/v1/kategori/',

      kwitansi : `/akunting/api/kwitansi/`,
      kwitansiv1 : `/akunting/api/v1/kwitansi/`,
      exportInvoice : `/akunting/api/export-invoice/`,
      exportPembayaran : `/akunting/api/export-pembayaran/`,

      paymentGateway : `/master/api/v1/method-pembayaran-midtrans/`,
      cancelPayment : `/akunting/api/cancel-transaction-midtrans/`,
      
      provinsiKodePos : `/master/api/kodepos/provinsi`,
      kabupatenKodePos : `/master/api/kodepos/kabupaten`,
      kecamatanKodePos : `/master/api/kodepos/kecamatan`,
      kelurahanKodePos : `/master/api/kodepos/kelurahan`,
      lokasi : `/produk/api/lokasi`,
      master : `/master/api`,
      mikrotikFailure : `/billing/api/v1/mikrotik-failure/`,
      nearbyOdp : `/master/api/odp/nearby`,
      odpv1 : `/master/api/v1/lokasi-odp/`,
      pegawaiv1 : `/kontak/api/v1/pegawai/`,
      pegawaiTiketing : `/kontak/api/v1/pegawai-tiketing/`,
      approvePembayaranReferal: `/akunting/api/approve-pembayaran-referral/`,
      pembayaranReferalv1: `/akunting/api/v1/pembayaran-referral/`,
      pembelian : `/api/v1/pembelian/`,
      priodState : '/akunting/api/kwitansi/get-paid-period/',
      produk : `/produk/api/produk`,
      produkv1: `/produk/api/v1/produk/`,
      promov1 : `/produk/api/v1/promo/`,
      produkLog : `/produk/api/v1/produk-log/`,
      produkWithSn : `/produk/api/v1/produk-with-sn/`,      
      satuan : `/produk/api/satuan`,
      satuanv1 : `/produk/api/v1/satuan`,
      

      settingInvoice : `/master/api/setting-invoice/`,
      teknisPerangkat : `/api/v1/teknis-perangkat/`,
      teknisPerangkatTemp : `/api/v1/teknis-perangkat-temp/`,
      userv1 : `/auth/api/v1/user/`,
      vlan : `/master/api/vlan/`,
      vport : `/master/api/vport/`,
      configServer : `/master/api/v1/config-server/`,


      // aktivasi
      prosesAktivasiByTanggalAktivasi : `/billing/api/v1/proses-aktivasi-by-tanggal-aktivasi/`,
      

      // tiketing
      masterKategoriTiketing : `/tiketing/api/v1/master-kategori/`,
      tiket : `/tiketing/api/v1/tiket/`,
      tiketChangeState : `/tiketing/api/v1/tiket-change-state/`,
      tiketChat : `/tiketing/api/v1/tiket-chat/`,
      tiketChatUser :  `/tiketing/api/v1/tiket-chat-user/`,
      tiketHistory : `/tiketing/api/v1/tiket-log/`,
      assignTiket : `/tiketing/api/v1/assign-tiket/`,
      tiketTeams : `/tiketing/api/v1/tiket-task/`,
      kontakNotif : `/kontak/api/v1/kontak-notif/`,
      kontakNotifSetRead : `/kontak/api/v1/notif/set-isread/`      ,

      // whatsapp
      wasession : `/wamessage/api/v1/wa-session/`,
      createSession : `/wamessage/api/v1/create-session/`,
      templateWa : `/wamessage/api/v1/wa-template/`,
      sendMassage : `/wamessage/api/v1/send-message/`,
      checkConnectionWa : `/wamessage/api/v1/check-connection/`,
      testSendMessage : `/wamessage/api/v1/test-send-message-template/`,
      waTipeTemplateOption : `/wamessage/api/v1/tipe-template-option/`,
    }
};
  